import React from "react";
import styled from "styled-components";
import { colorMain, maxMobile } from "../styles";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { clearBg } from "../styles";

const Wrapper = styled.div`
  padding: 32px 0 32px;
  background: ${clearBg};
`;

const BoxWrapper = styled.div`
  max-width: 384px;
  margin: 0 auto;
  text-align: center;
`;

const Display = styled.div`
  margin-bottom: 24px;

  @media (${maxMobile}) {
    flex: 0 1 auto;
    width: 159px;
    margin-top: 16px;
    order: 2;

    .container {
      flex-direction: column;
    }
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h1`
  margin: 0 0 12px;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 133%;
  letter-spacing: -0.021em;
  color: ${colorMain};
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 133%;
  letter-spacing: -0.014em;
  color: ${colorMain};
`;

const PostWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 64px;
`;

const AboutLayout = ({
  image,
  title,
  description,
  content,
  contentComponent,
  isPreview
}) => {
  const CurrentContent = contentComponent || content;

  return (
    <div className={`about-page`}>
      <Wrapper className={`about-top`}>
        <div className="container">
          <BoxWrapper className={`box`}>
            {image && (
              <Display className={`display`}>
                <PreviewCompatibleImage imageInfo={image} />
              </Display>
            )}
            <Content className={`about-content`}>
              <Title className={`title`}>{title}</Title>
              <Text className={`description`}>{description}</Text>
            </Content>
          </BoxWrapper>
        </div>
      </Wrapper>

      <div className="container">
        <PostWrapper className="post-content">
          {!isPreview && <CurrentContent content={content} />}
          {isPreview && content}
        </PostWrapper>
      </div>
    </div>
  );
};

export default AboutLayout;
